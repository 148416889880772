<template>
  <div class="aali_tm_section help" id="portfolio">
    <div class="aali_tm_portfolio wow fadeInUp" data-wow-duration="1s">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="light"
        >
          <span>Selected works</span>
          <h3>{{ $t("open_portfolio") }}</h3>
        </div>
        <div class="portfolio_filter">
          <ul>
            <li><a href="#">HTML5</a></li>
            <li><a href="#">CSS3</a></li>
            <li><a href="#">RWD</a></li>
            <li><a href="#">UX</a></li>
            <li><a href="#">Ajax</a></li>
            <li><a href="#">Vue3.js</a></li>
          </ul>
          <ul>
            <li><a href="#">JQuery</a></li>
            <li><a href="#">Git.</a></li>
            <li><a href="#">ASP.NET</a></li>
            <li><a href="#">.NET C#</a></li>
            <li><a href="#">.NET Core</a></li>
          </ul>
          <ul>
            <li><a href="#">Python</a></li>
            <li><a href="#">MSSQL</a></li>
            <li><a href="#">MySQL</a></li>
            <li><a href="#">IIS</a></li>
          </ul>
        </div>
        <div class="portfolio_list">
          <ul class="gallery_zoom">
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/topspeed.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Front End Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="#"
                  @click.prevent="
                    () => onClick('img', 'img/portfolio/topspeed.png')
                  "
                ></a>
              </div>
            </li>
            <!-- gallery item end -->
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/TammyArt_Web.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Landing Page Website</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="http://tammyart-hsiao.com/" target="_blank"
                ></a>
              </div>
            </li>
            <!-- gallery item end -->
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/FST.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="https://supplyonline.fstech.com.tw/"
                  target="_blank"
                ></a>
              </div>
            </li>
            <!-- gallery item end -->
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div
                    class="main"
                    style="background-image: url('img/portfolio/FHI.png')"
                  ></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="https://www.fhi.com.tw/official/Index.aspx"
                  target="_blank"
                ></a>
              </div>
            </li>
            <!-- gallery item end -->
            <li class="gallery_item">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/ITEC.png"></div>
                </div>
                <div class="overlay"></div>
                <div v-html="text"></div>
                <div class="details">
                  <span>Website</span>
                  <h3>Full Stack Develop</h3>
                </div>
                <a
                  class="aali_tm_full_link zoom"
                  href="https://cloud.fpcetg.com.tw/FPC/WEB/EC/ITEC_Service_Platform/Index.aspx"
                  target="_blank"
                ></a>
              </div>
            </li>
            <!-- gallery item end -->
          </ul>
        </div>
      </div>
    </div>
    <MagnificPopUp :modal="modal" :close="closeModal" :name="name" :src="src" />
  </div>
</template>

<script>
import MagnificPopUp from "./MagnificPopUp.vue";
// import ModalBox from "./ModalBox.vue";
import { maximize, soundcloud, text, vimeo, youtube } from "./svgImage";
export default {
  name: "Portfolio",
  data() {
    return {
      vimeo,
      youtube,
      soundcloud,
      text,
      maximize,
      // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".gallery_item",
        layoutMode: "fitRows",
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      alert("close");
      this.active = 0;
    },
  },
  components: { MagnificPopUp },
};
</script>
